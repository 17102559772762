export const menuRules = {
  ADMIN: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaMasivaTotal: false,
    ConsultaPage: true,
    /* SAC */
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: true,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoIngresoBodega: true,
    ManifiestosSalidaInhouse: true,
    ManifiestosRetorno: true,
    ManifiestoSalida: true,
    ManifiestoRetornoCliente: true,
    ManifiestoEntradaAgente: true,
    ManifiestoEntradaSantiago: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    VariosOperaciones:true,
    ListaCorreos: true,
    CubicosOperaciones: true,
    ListadoPendientes: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    DescargaManifiestos: true,
    /* ADMINISTRACION */
    PortalUsuarios: true,
    PortalClientes: true,
    PortalChoferes: true,
    AsignacionClientesEjecutivas: true,
    ControlSupervisora: true,
    /* SAC */
    CediblesClientePage: true,
    RetirosClienteStgo: false,
    RetirosClienteInter: false,
    ManifiestosSalidaInhouseCliente: true,
    /* CONSULTAS */
    ConsultaIndividualClientes: true,
    ConsultaMasivoCliente: true,
    /* GRAFICOS */
    /* ReportabilidadAgentes: true, */
    ReportabilidadEjecutiva: true,
    ReportabilidadGeneral: true,
     /* CONTROL CALIDAD */
     ClientesControlCalidad: true,
  },
  SAC: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: false,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    VariosOperaciones:true,
    ListadoPendientes: true,
    DescargaManifiestos: true,
  },
  SUPERVISORA: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: false,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    /* OPERACIONES */
    VariosOperaciones:true,
    ListadoPendientes: true,
    DescargaManifiestos: true,
    /* ADMINISTRACION */
    AsignacionClientesEjecutivas: true,
    ControlSupervisora: true,
    PortalChoferes: true,
  },
  FACTURACION: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    /* SAC */
    correccionOrden: true,
    Listados: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    VariosOperaciones:true,
    DescargaManifiestos: true,
  },
  OPERACIONES: {
    inicio: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoEntradaAgente: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ManifiestosSalidaInhouse: true,
    ManifiestoEntradaSantiago: true,
    ManifiestoRetornoCliente: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    VariosOperaciones:true,
    CubicosOperaciones: true,
    DescargaManifiestos: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    /* SAC */
    /* FACTURACION */
  },
  SUPERVISOR: {
    inicio: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoEntradaAgente: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ManifiestoRetornoCliente: true,
    ManifiestosSalidaInhouse: true,
    ManifiestoEntradaSantiago: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    VariosOperaciones:true,
    ListaCorreos: true,
    CubicosOperaciones: true,
    DescargaManifiestos: true,
    ListadoPendientes: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    /* SAC */
    Listados: true,
    correccionOrden: true,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
  },
  AGENTE: {
    inicio: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoEntradaAgente: true,
    ManifiestosRetorno: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    VariosOperaciones:true,
    DescargaManifiestos: true,

    /* CONSULTAS */
    ConsultaIndividualAgentes: true,
    ConsultaMasivoAgentes: true,
  },
  AGENTE_HUB: {
    inicio: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoEntradaAgente: true,
    ManifiestosRetorno: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ManifiestosSalidaInhouse: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    VariosOperaciones:true,
    DescargaManifiestos: true,
  },

  AGENTE_ADMIN: {
    inicio: true,
    /* OPERACIONES */
    PorOD: true,
    ManifiestoEntradaAgente: true,
    ManifiestosRetorno: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ManifiestosSalidaInhouse: true,
    ListadosManifiestos: true,
    PorCliente: true,
    ManifiestoRutaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    VariosOperaciones:true,
    DescargaManifiestos: true,
    /* CONSULTAS */
    ConsultaIndividualAgentes: true,
    ConsultaMasivoAgentes: true,
  },
  CLIENTE: {
    inicio: true,
    /* SAC */
    CediblesClientePage: true,
    ControlCediblesNovo: true,
    RetirosClienteStgo: false,
    RetirosClienteInter: false,
    ManifiestosSalidaInhouseCliente: true,
    DescargaManifiestos: true,
    GestionOrdenesClientes: true,
    /* CONSULTAS */
    ConsultaIndividualClientes: true,
    ConsultaIndividualClientesCodigoBarra: true,
    ConsultaMasivoCliente: true,
  },
  GRAFICOS: {
    inicio: true,
    /* ReportabilidadAgentes: true, */
    ReportabilidadEjecutiva: true,
    ReportabilidadGeneral: true,
  },
  JEFATURA: {
    inicio: true,
     /* CONSULTAS */
     ConsultaIndividual: true,
     ConsultaMasivo: true,
     ConsultaMasivaTotal: false,
     ConsultaPage: true,
     /* SAC */
     Listados: true,
    /* graficos */
    ReportabilidadEjecutiva: true,
    ReportabilidadGeneral: true,
    /* OPERACIONES */
    VariosOperaciones:true,
    DescargaManifiestos: true,
  },
  CONTROL_CALIDAD: {
    inicio: true,
     /* CONSULTAS */
     ConsultaIndividual: true,
     ConsultaPage: true,
     /* SAC */
     Listados: true,
     /* menucalidad */
     ClientesControlCalidad: true,
  },
};

export default menuRules;
