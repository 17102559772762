import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const editarCedible = async (id, data) => {
  try {
    const response = await axios.put(`${BASE_API_URL}cedibles/editarGuiasMalCargadas/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const eliminarCedible = async (id) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}cedibles/eliminarGuiasMalCargadas/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el cedible:", error);
    throw error;
  }
};

export const obtenerGuiasPendientesClientes = async (codCliente, fechaDesde, fechaHasta) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/obtenerGuiasPendientesClientes`, {
      codCliente,
      fechaDesde,
      fechaHasta,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener cedibles segun el cliente */
export const ObtenerCediblesCliente = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/ObtenerCedibles`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener descargar cedibles masivo por mes */
export const DescargarCediblesMasivoCliente = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/descargarCediblesMasivo`, data, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener tipo de usuario */
export const ObtenerTipoUsuarioCliente = async (codCliente) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/ObtenerTipoUsuario`, { id: codCliente });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* buscar los cedibles */
export const buscarCedible = async (formData, onUploadProgress) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/buscarCedible`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: onUploadProgress,
    });
    return response.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
};

/* subir manualmente los cedibles */
export const subirCedibleManualmente = async (formData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/subirCedibleManualmente`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error;
  }
};

export const obtenerClientesControlSac = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DescargarCediblesMasivoControlSac = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/descargarCediblesMasivo`, data, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ObtenerCediblesMalCargadas = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/ObtenerCediblesMalCargadas`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ObtenerCediblesControlSac = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/ObtenerCedibles`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ObtenerCediblesNovo = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}cedibles/obtenerCediblesNovo`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtenemos clientes */
export const obtenerClientes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
