import React, { useState, memo, useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { TextField, Paper, Box, Typography } from "@mui/material";
import {
  ObtenerCediblesMalCargadas,
  ObtenerCediblesControlSac,
  ObtenerCediblesNovo,
  obtenerClientesControlSac,
  obtenerGuiasPendientesClientes,
  editarCedible,
  eliminarCedible,
} from "../../services/CediblesService";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import ReusableTableCedibles from "../../components/DespachoComponentes/componentesCedibles/ReusableTableCedibles";
import ReusableTableCediblesPendientes from "../../components/DespachoComponentes/componentesCedibles/ReusableTableCediblesPendientes";
import TableModificar from "../../components/DespachoComponentes/componentesCedibles/TableModificar";
import CustomDate from "../../utils/CustomDate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Swal from "sweetalert2";

const ControlCediblesSac = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [dataTablaModificar, setDataTablaModificar] = useState([]);
  const [dataTablaPendientes, setDataTablaPendientes] = useState([]);
  const [dataTabla, setDataTabla] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [isLoadingCedibles, setIsLoadingCedibles] = useState(false);
  const [isLoadingCediblesPendientes, setIsLoadingCediblesPendientes] = useState(false);
  const [isLoadingCediblesModificar, setIsLoadingCediblesModificar] = useState(false);
  const [esPrimeraBusqueda, setEsPrimeraBusqueda] = useState(true);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState(null);

  const columns = [
    { field: "guia", headerName: "Guía", width: 100 },
    { field: "odPapel", headerName: "OD Papel", width: 100 },
    { field: "fecha", headerName: "Fecha creacion", width: 160 },
    { field: "fechaDigitacion", headerName: "Fecha digitacion", width: 130 },
    {
      field: "pdf",
      headerName: "PDF",
      width: 40,
      renderCell: (params) => createPdfIconLink(params.row.cedibleBase64, `${params.row.guia}.pdf`),
    },
  ];

  const columnsPendientes = [
    { field: "ID_REFERENCIA_MODIFICADO", headerName: "Guía", width: 90 },
    { field: "OD_PAPEL", headerName: "OD Papel", width: 90 },
    { field: "NOMBRE_CLIENTE", headerName: "Nombre Cliente", width: 160 },
    {
      field: "FH_SYS",
      headerName: "Fecha creación",
      width: 180,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = date.toLocaleString("es-CL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        return formattedDate;
      },
    },
  ];

  const columnsTableModificar = [
    {
      field: "acciones",
      headerName: "Acciones",
      width: 100,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            <EditIcon
              style={{ cursor: "pointer", color: "#041562", marginRight: "8px" }}
              onClick={() => handleEdit(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", color: "#DA251C", marginLeft: "8px" }}
              onClick={() => handleDelete(params.row)}
            />
          </div>
        );
      },
    },
    { field: "guia", headerName: "Guía", width: 100 },
    { field: "fecha", headerName: "Fecha creación", width: 180 },
    {
      field: "cedibleBase64",
      headerName: "PDF",
      width: 40,
      renderCell: (params) => createPdfIconLink(params.row.cedibleBase64, `${params.row.guia}.pdf`),
    },
  ];

  const handleEdit = (row) => {
    setCurrentEditRow(row);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    if (currentEditRow && currentEditRow.id) {
      try {
        const data = { guia: currentEditRow.guia };
        await editarCedible(currentEditRow.id, data);
        toast.success("Cedible editado correctamente!");
        setOpenEditDialog(false);
        await cediblesMalCargadas();
        await descargarCedibles();
        await obtenerDatosPendientes();
      } catch (error) {
        console.error("Error al editar el cedible:", error);
        toast.error("Error al editar el cedible.");
      }
    }
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarCedible(row.id)
          .then((response) => {
            Swal.fire("Eliminado!", "El cedible ha sido eliminado.", "success");
            cediblesMalCargadas();
          })
          .catch((error) => {
            console.error("Error al eliminar el cedible:", error);
            Swal.fire("Error!", "No se pudo eliminar el cedible.", "error");
          });
      }
    });
  };

  const createPdfIconLink = (base64Image, fileName) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const imgData = "data:image/jpeg;base64," + base64Image;
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 10;
    let imgHeight = pageHeight - 2 * margin;
    let imgWidth = imgHeight / (3 / 4);
    if (imgWidth > pageWidth - 2 * margin) {
      imgWidth = pageWidth - 2 * margin;
    }
    const x = (pageWidth - imgWidth) / 2;
    const y = margin;
    pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);
    const pdfUrl = pdf.output("bloburl");
    return (
      <a href={pdfUrl} download={fileName} title="Descargar PDF">
        <PictureAsPdfIcon style={{ color: "red" }} />
      </a>
    );
  };

  /* Función para buscar los pendientes */
  const obtenerDatosPendientes = async () => {
    try {
      if (!selectedClient || !fechaDesde || !fechaHasta) {
        toast.error("Seleccione un cliente y las fechas antes de buscar.");
        return;
      }
      setDataTablaPendientes([]);
      const formattedFechaDesde = fechaDesde.toISOString().split("T")[0];
      const formattedFechaHasta = fechaHasta.toISOString().split("T")[0];
      setIsLoadingCediblesPendientes(true);
      const data = await obtenerGuiasPendientesClientes(selectedClient.id, formattedFechaDesde, formattedFechaHasta);
      // Si no hay datos, deja la tabla vacía
      if (data.length === 0) {
        setDataTablaPendientes([]);
        toast.info("No se encontraron guías pendientes para el cliente y fechas seleccionados.");
      } else {
        setDataTablaPendientes(data);
      }
    } catch (error) {
      console.error("Error al obtener guías pendientes:", error);
      toast.warning("No hay pendientes.");
      setDataTablaPendientes([]);
    } finally {
      setIsLoadingCediblesPendientes(false);
    }
  };

  /* Función para descargar todos los cedibles por rango de fecha */
  const descargarCedibles = async () => {
    try {
      setIsLoadingCedibles(true);
      const clienteId = getCodClienteForRequest();
      const formattedFechaDesde = fechaDesde.toISOString().split("T")[0];
      const formattedFechaHasta = fechaHasta.toISOString().split("T")[0];
      const data = {
        codCliente: clienteId,
        fechaDesde: formattedFechaDesde,
        fechaHasta: formattedFechaHasta,
      };
      let response;
      if (clienteId === 632) {
        /* ID de NOVOFARMA */
        response = await ObtenerCediblesNovo(data);
      } else {
        response = await ObtenerCediblesControlSac(data);
      }
      if (!response || !response.registros || response.registros.length === 0) {
        toast.error("No hay guias cargadas para el cliente seleccionado.");
        setDataTabla([]);
        return;
      }

      setDataTabla(response.registros);
      if (esPrimeraBusqueda) {
        toast.success("Cedibles encontrados!");
        setEsPrimeraBusqueda(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al buscar cedibles.");
      setDataTabla([]);
    } finally {
      setIsLoadingCedibles(false);
    }
  };

  /* Función para descargar todos los cedibles por rango de fecha */
  const cediblesMalCargadas = async () => {
    try {
      setIsLoadingCediblesModificar(true);
      const clienteId = getCodClienteForRequest();
      const data = { codCliente: clienteId };
      let response;
      response = await ObtenerCediblesMalCargadas(data);
      setDataTablaModificar(response.registros);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al buscar cedibles.");
      setDataTablaModificar([]);
    } finally {
      setIsLoadingCediblesModificar(false);
    }
  };

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  /* formatear fecha digitacion de tabla */
  const formatDateDigitacion = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const rows = dataTabla.map((fila) => ({
    id: fila.ID,
    fecha: formatDate(fila.FH_SYS),
    fechaDigitacion: formatDateDigitacion(fila.FECHA_DIGITACION),
    codigoCliente: fila.COD_CLIENTE,
    guia: fila.GUIA,
    odPapel: fila.OD_PAPEL,
    cedibleBase64: fila.CEDIBLE_BASE64,
  }));

  const rowsModificar = dataTablaModificar.map((fila) => ({
    id: fila.ID,
    guia: fila.GUIA,
    fecha: formatDate(fila.FH_SYS),
    cedibleBase64: fila.CEDIBLE_BASE64,
  }));

  const realizarBusqueda = async () => {
    setEsPrimeraBusqueda(true);
    setIsLoadingCedibles(true);
    setIsLoadingCediblesPendientes(true);
    try {
      await descargarCedibles();
      await obtenerDatosPendientes();
      await cediblesMalCargadas();
    } catch (error) {
      console.error("Error durante la búsqueda de cedibles o guías pendientes:", error);
      toast.error("Error al realizar la búsqueda.");
    } finally {
      setIsLoadingCedibles(false);
      setIsLoadingCediblesPendientes(false);
    }
  };

  const getCodClienteForRequest = () => {
    return selectedClient ? selectedClient.id : null;
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Paper style={{ padding: "10px" }}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={5}>
              <Autocomplete
                id="combo-box-cliente"
                options={clientes.map((cliente) => ({
                  id: cliente.ID,
                  nombre: `${cliente.NOMBRE} (ID: ${cliente.ID})`,
                }))}
                getOptionLabel={(option) => option.nombre}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedClient}
                onChange={(event, newValue) => setSelectedClient(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nombre Cliente"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomDate
                label="Fecha Desde"
                value={fechaDesde}
                onChange={(newValue) => {
                  setFechaDesde(newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomDate
                label="Fecha Hasta"
                value={fechaHasta}
                onChange={(newValue) => {
                  setFechaHasta(newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ marginTop: "10px" }}>
              <Button
                sx={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#031042",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#666",
                    color: "#FFF",
                  },
                }}
                onClick={realizarBusqueda}
                variant="contained"
                color="primary"
                disabled={isLoadingCedibles}
                fullWidth
              >
                {isLoadingCedibles ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Cedibles Cargadas</Typography>
            <Box sx={{ height: 340, width: "100%" }}>
              <ReusableTableCedibles columns={columns} rows={rows} loading={isLoadingCedibles} searchField="guia" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Cedibles Pendientes</Typography>
            <Box sx={{ height: 340, width: "100%" }}>
              <ReusableTableCediblesPendientes
                columns={columnsPendientes}
                rows={dataTablaPendientes}
                loading={isLoadingCediblesPendientes}
                searchField="ID_REFERENCIA_MODIFICADO"
              />
            </Box>
          </Grid>
        </Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="h6" style={{ marginTop: "5%" }}>
            Cedibles Mal Cargadas
          </Typography>
          <Box sx={{ height: 250, width: "100%" }}>
            <TableModificar
              columns={columnsTableModificar}
              rows={rowsModificar}
              loading={isLoadingCediblesModificar}
              searchField="ID_REFERENCIA"
            />
          </Box>
        </Grid>
        {!isLoadingCedibles && dataTabla.length === 0 && !esPrimeraBusqueda && (
          <Paper style={{ padding: "20px" }}>
            <Typography textAlign="center">No se encontraron cedibles para los criterios de búsqueda.</Typography>
          </Paper>
        )}
      </Container>
      
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Editar Cedible</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="guia"
            label="Número de Guía"
            type="text"
            fullWidth
            variant="outlined"
            value={currentEditRow ? currentEditRow.guia : ""}
            onChange={(e) => setCurrentEditRow({ ...currentEditRow, guia: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancelar</Button>
          <Button onClick={() => handleSaveChanges()}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(ControlCediblesSac);
