import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ListSubheader,
} from "@mui/material";
import { guardarCabecerasUsuario, obtenerCabecerasPersonalizadas } from "../../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultHeaders = [
  "CLIENTE",
  "ORDEN",
  "ORDEN PAPEL",
  "DOCUMENTO",
  "CODIGO DE BARRA",
  "EMBALAJE",
  "DISPOSITIVO",
  "FECHA DIGITACION",
  "NOTA",
  "BULTOS",
  "PESO",
  "ALTO",
  "LARGO",
  "ANCHO",
  "PESO VOLUMEN",
  "ESTADO ACTUAL",
  "DESTINATARIO",
  "DIRECCION",
  "TIPO SERVICIO DESPACHO / RETIRO",
  "COMUNA ORIGEN",
  "IATA PADRE ORIGEN",
  "COMUNA DESTINO",
  "IATA PADRE DESTINO",
  "TIPO DE CARGA",
  "VIA SERVICIO",
  "CENTRO DE COSTO",
  "TIPO DE NEGOCIO",
  "CANAL",
  "TIEMPO TRANSITO",
  "SLA GESTION",
  "KPI",
  "NOMBRE RECEPTOR",
  "RUT RECEPTOR",
  "FECHA RECEPCION",
  "HORA RECEPCION",
  "RECEPTOR FOTO",
  "PRIMER EVENTO", 
  "PRIMER EVENTO CHOFER", 
  "PRIMER EVENTO FECHA", 
  "PRIMER EVENTO HORA", 
  "PRIMER EVENTO NOMBRE", 
  "PRIMER EVENTO RUT", 
  "PRIMER EVENTO NOTA", 
  "PRIMER EVENTO FOTO", 
  "SEGUNDO EVENTO", 
  "SEGUNDO EVENTO CHOFER", 
  "SEGUNDO EVENTO FECHA", 
  "SEGUNDO EVENTO HORA", 
  "SEGUNDO EVENTO NOMBRE", 
  "SEGUNDO EVENTO RUT", 
  "SEGUNDO EVENTO NOTA", 
  "SEGUNDO EVENTO FOTO", 
  "TERCER EVENTO", 
  "TERCER EVENTO CHOFER", 
  "TERCER EVENTO FECHA", 
  "TERCER EVENTO HORA", 
  "TERCER EVENTO NOMBRE", 
  "TERCER EVENTO RUT", 
  "TERCER EVENTO NOTA", 
  "TERCER EVENTO FOTO", 
  "CUARTO EVENTO", 
  "CUARTO EVENTO CHOFER", 
  "CUARTO EVENTO FECHA", 
  "CUARTO EVENTO HORA", 
  "CUARTO EVENTO NOMBRE", 
  "CUARTO EVENTO RUT", 
  "CUARTO EVENTO NOTA", 
  "CUARTO EVENTO FOTO",
  "CONTACTO",
  "TELEFONO",
  "FECHA IMPRESIÓN ETIQUETA",
  "AGENTE ASIGNADO",
  "CEDIBLES",
  "INHOUSE",
  "ENTRADA ANDEN",
  "SALIDA ANDEN",
  "ENTRADA AGENTE",
  "SALIDA RUTA",
  "SALIDA AGENTE",
  "INGRESO SANTIAGO",
  "MANIFIESTO RETORNO CLIENTE",
  "MANIFIESTO APP",
  "LABORATORIO",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HeaderSelector = ({ userId, userHeaders, onUpdateHeaders }) => {
  const [selectedHeaders, setSelectedHeaders] = useState(userHeaders || defaultHeaders);

  useEffect(() => {
    setSelectedHeaders(userHeaders.length > 0 ? userHeaders : defaultHeaders);
  }, [userHeaders]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedHeaders(typeof value === "string" ? value.split(",") : value);
  };

  const selectAllHeaders = () => {
    setSelectedHeaders(defaultHeaders);
  };

  const deselectAllHeaders = () => {
    setSelectedHeaders([]);
  };

  const savePreferences = async () => {
    try {
      await guardarCabecerasUsuario(userId, selectedHeaders);
      const updatedHeaders = await obtenerCabecerasPersonalizadas(userId);
      toast.success("Cabeceras guardadas con éxito");
      onUpdateHeaders(updatedHeaders); // Actualizar con cabeceras frescas del servidor
    } catch (error) {
      console.error("Error al guardar cabeceras:", error);
      toast.error("Error al guardar cabeceras");
    }
  };
  

  return (
    <FormControl fullWidth>
      <InputLabel>Selecciona Cabeceras para archivo excel</InputLabel>
      <Select
        multiple
        value={selectedHeaders}
        onChange={handleChange}
        input={<OutlinedInput label="Selecciona Cabeceras para archivo excel" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <ListSubheader>
          <Button onClick={selectAllHeaders} color="primary">
            Seleccionar Todas
          </Button>
          <Button onClick={deselectAllHeaders} color="primary">
            Deseleccionar Todas
          </Button>
        </ListSubheader>
        {defaultHeaders.map((header) => (
          <MenuItem key={header} value={header}>
            <Checkbox checked={selectedHeaders.includes(header)} />
            <ListItemText primary={header} />
          </MenuItem>
        ))}
      </Select>
      <Button onClick={savePreferences} style={{ marginTop: 20 }}>
        Guardar Preferencias
      </Button>
    </FormControl>
  );
};

export default HeaderSelector;
